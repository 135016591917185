import React from 'react';

const DropdownFilter = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = React.useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onSelect(selectedValue);
  };

  return (
    <select value={selectedOption} onChange={handleChange}>
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};



function App() {

  const [propData, setPropData] = React.useState({});
  const [filterValue, setFilterValue] = React.useState('');


  const fetchData = async () => {
    let dnode = window.dnode;
    console.log(dnode)
    if(window.dnode === undefined) {
      dnode = 0;
    }
		fetch(`https://properties.tldr.dev/prox.php?type=notifications&nid=${dnode}`)
			.then(res => res.json())
			.then(data => setPropData(data))
  }



  React.useEffect(function() {
    fetchData();
	}, [])

  const propHeader = () => {
    return <thead className="prop_header">
      <tr>
      <th>Date</th>
      <th>Attention</th>
      <th>Subject</th>
      <th>Notes</th>
      <th>Complete</th>
      </tr>
    </thead>
  }

  const propRows = (properties) => {
    
    const handleClick = async (key) => {

      let url = 'https://properties.tldr.dev/api/complete/' + key
      const completeResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const completeData = await completeResponse.json();

      fetchData();

    };    

		return Object.entries(properties)
      .filter(([key, property]) => filterValue === '' || property?.type === filterValue)
      .map(([key, property]) =>
			<tr key={key} className="prop_row">
      <td> <a target="_blank" rel="noreferrer" href={`https://properties.tldr.dev/node/${key}`}>{property.date}</a></td>
      <td> {Object.values(property.users).map((user) => user.name).join(", ")} </td>
			<td> {property.title} </td>
      <td> {property.notes}</td>
      <td><button onClick={() => handleClick(key)}>Complete</button></td>
			</tr>
	)}


  const handleFilter = (selectedValue) => {
    setFilterValue(selectedValue);

  };

  return (
    <div className="App">
      <div className="notify_header container row">
        <span className="col-md-4 col-sm-1"><a href="/node/add/notification">Create Notification</a></span>
        <span className="col-md-4 col-sm-1"><a href="/node/add/property">Create Property</a></span>
        <span className="col-md-4 col-sm-1"><a href="/node/add/sale">Create MC Sale</a></span>
      </div>
      <DropdownFilter options={['Property', 'Lien']} onSelect={handleFilter} />
      <table className="prop_table table-responsive-lg">
      {propHeader()}
			<tbody>
      {propData ? propRows(propData) : 'LOADING'}
			</tbody>
			</table>
    </div>
  );
}

export default App;
